// src/components/discover.js
import '../styles/discover/header.css';
import '../styles/discover/discover.css';
import '../styles/discover/footer.css';
import '../styles/discover/cta.css';
import FAQ from './side-sections/side-section.js';


import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

const Discover = () => {
    
    const location = useLocation();
  
    // Scroll restoration logic (if needed)
    useEffect(() => {
      const scrollPositiondiscover = sessionStorage.getItem('scrollPositiondiscover');
      if (scrollPositiondiscover) {
        window.scrollTo(0, parseInt(scrollPositiondiscover, 10));
      }
      const handleScroll = () => {
        sessionStorage.setItem('scrollPositiondiscover', window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [location]);
  

    return (
        <div>
            <div className="header"> 
                <Link to="/">
                    <img className="logo" src="/images/3.png" alt="logo" />
                </Link>
                <Link to="/" className="welcome">
                    <div>welcome</div>
                </Link>
                <div className="discover">Discover</div>
                <Link to="/Pricing" className="pricing">
                    <div>pricing</div>
                </Link>
                <Link to="/call" className="call">
                    <div>Call us</div>
                </Link>
            </div>
            <>
                <Link to={'/Login'}>
                <div className="login-icon">
                   <i className="fas fa-user"></i> Login
                </div>
                </Link>
            </>

            <main>
                <div id="trending-section">
                    {/* Add content for trending section here */}
                </div>

                <div id="testimonials-section">
                    <h2>What Our Clients Say</h2>
                    <div className="testimonials-video">
                        <div className="testimonials-content">
                            <div className="testimonial">
                                <p>"I chose the free Lite plan and was impressed by the thorough website review. Identified key issues for free! Vyrex is a fantastic platform!"</p>
                                <p className="client">- Neha Patel, Blogging Website</p>
                            </div>
                            <div className="testimonial">
                                <p>"I tried the free Lite plan and was amazed by its potential. It convinced me to opt for their paid services. Great value and quality!"</p>
                                <p className="client">- Taylor Lee, Marketing Director at Creative Ventures</p>
                            </div>
                            <div className="testimonial">
                                <p>"Vyrex offers affordable SEO services with industry-best quality. Their detailed analysis and recommendations helped improve our site's performance significantly!"</p>
                                <p className="client">- John Doe, Startup Tech Company</p>
                            </div>
                        </div>
                        <div className="video-container">
                            <video src="/videos/Recording 2024-09-07 202721.mp4" autoPlay loop muted></video>
                        </div>
                    </div>
                </div>

                <div id="why-choose-vyrex">
                    <h2>Why Choose Vyrex?</h2>
                    <p>Vyrex stands out in the SEO industry for its commitment to providing top-notch, customized SEO solutions at affordable rates. Here’s why our clients choose us:</p>
                    <ul>
                        <li><strong>Free Lite Plan:</strong> Experience our services with our free Lite plan, which includes a detailed website review and key issue identification.</li>
                        <li><strong>Comprehensive SEO Services:</strong> From keyword research to content marketing, we cover all aspects of SEO to boost your online presence.</li>
                        <li><strong>Affordable Pricing:</strong> Our competitive pricing ensures you get the best value for high-quality SEO services.</li>
                        <li><strong>Tailored Solutions:</strong> We offer personalized recommendations based on thorough website audits to address your specific needs.</li>
                    </ul>
                </div>

                {/* Video and CTA Section */}
                <div id="video-cta-section">
                    <div className="video-container">
                        <video src="/videos/Recording 2024-09-13 150217.mp4" autoPlay loop muted></video>
                    </div>
                    <div className="cta-container">
                        <h2>Ready to Get Started?</h2>
                        <p>Take the next step towards optimizing your online presence with Vyrex. Contact us today to learn more about our services or get started with a plan that fits your needs!</p>
                        <a href="call.html" className="cta-button">Contact Us</a>
                    </div>
                </div>

                <FAQ />

                <footer className="footer">
                    <div className="footer-section footer-logo">
                        <img src="/images/3.png" alt="Vyrex Logo" className="logo" />
                    </div>
                    <div className="footer-container">
                        <div className="footer-section footer-links">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><a href="Home#introduction-section">Home</a></li>
                                <li><a href="#about-us">About Us</a></li>
                                <li><a href="/Home#services-overview">Services</a></li>
                                <li><a href="#blog">Blog</a></li>
                                <li><a href="#contact">Contact Us</a></li>
                                <li><a href="Pricing">Pricing</a></li>
                            </ul>
                        </div>

                        <div className="footer-section footer-legal">
                            <h4>Legal</h4>
                            <ul>
                                <li><a href="#terms-of-service">Terms of Service</a></li>
                                <li><a href="#privacy-policy">Privacy Policy</a></li>
                                <li><a href="#cookie-policy">Cookie Policy</a></li>
                                <li><a href="#disclaimer">Disclaimer</a></li>
                                <li><a href="#faq-section">FAQ</a></li>
                            </ul>
                        </div>

                        <div className="footer-section footer-social">
                            <h4>Follow Us</h4>
                            <div className="social-icons">
                                <a href="https://facebook.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/facebook-new.png" alt="Facebook" /></a>
                                <a href="https://twitter.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/twitter.png" alt="Twitter" /></a>
                                <a href="https://linkedin.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/linkedin.png" alt="LinkedIn" /></a>
                            </div>
                        </div>
                    </div>

                    <hr className="footer-divider" />
                    <div className="footer-bottom">
                        <p>© 2024 Vyrex. All rights reserved.</p>
                    </div>
                </footer> 
            </main>
        </div>
    );
};

export default Discover;
