import React from "react";
import { Link } from "react-router-dom";
import "../styles/login/login.css";

function Login() {
  return (
    <div>
      {/* Header remains unchanged */}
      <div className="header">
        <Link to="/"><img className="logo" src="images/3.png" alt="logo" /></Link>
        <Link to="/" className="welcome">Welcome</Link>
        <Link to="/Discover" className="discover">Discover</Link>
        <Link to="/Pricing" className="pricing">Pricing</Link>
        <Link to="/Call" className="call">Call us</Link>
      </div>

      {/* Modified login section */}
      <section className="login-section">
        <form className="login-form">
          <div className="form-header">
            <h2>Login</h2>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Enter your email" />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" placeholder="Enter your password" />
          </div>

          <div className="form-group">
            <input type="checkbox" id="agree-terms" />
            <label htmlFor="agree-terms">I agree to the terms and conditions</label>
          </div>

          <div className="form-group">
            <button type="submit" className="login-submit">Login</button>
          </div>

          <div className="form-footer">
            <Link to="/signup">Don't have an account? Sign up</Link>
          </div>
        </form>
      </section>
    </div>
  );
}

export default Login;
