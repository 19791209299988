import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../styles/side-section-css/payment.css';

import { Link } from 'react-router-dom';

function Paymentpremium() {
  const location = useLocation();

  // Scroll restoration logic
  useEffect(() => {
    const scrollPositioncall = sessionStorage.getItem('scrollPositioncall');
    if (scrollPositioncall) {
      window.scrollTo(0, parseInt(scrollPositioncall, 10));
    }
    const handleScroll = () => {
      sessionStorage.setItem('scrollPositioncall', window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div className="payment-page">
      {/* Logo Section */}
      <div className="logo-container">
        <Link to='/Home'><img src="images/3.png" alt='Vyrex Logo' className="vyrex-logo" /></Link>
      </div>

      {/* Page Heading */}
      <h1 className="page-heading">Complete Your Payment for Vyrex SEO Services</h1>

      {/* Main Content Section */}
      <section className="main-content">
        {/* Payment Form */}
        <div className="payment-form">
          <h2 className="form-title">Payment Information</h2>
          <input type="text" placeholder='*Cardholder Name' />
          <input type="text" placeholder='*Card Number' />
          <input type="text" placeholder='*Expiration Date (MM/YY)' />
          <input type="text" placeholder='*CVV' />
          <div className="payment-summary">
            Total: <strong>$999/month</strong> (Premium Plan)
          </div>

          <button className="submit-payment-button">Pay Now</button>
        </div>

        {/* What's Next Section */}
        <div className="call-text">
          <h2>What Happens Next?</h2>
          <div>
            <h4>Sign Up with Vyrex</h4>
            <p><span className="arrow">✨</span> The first step is to create an account.</p>
          </div>
          <div>
            <h4>Purchase Your SEO Plan</h4>
            <p><span className="arrow">✨</span> Select and purchase the SEO plan that suits the user's business.</p>
          </div>
          <div>
            <h4>Access Your Dashboard</h4>
            <p><span className="arrow">✨</span> Users will get access to their dashboard right after purchasing.</p>
          </div>
          <div>
            <h4>Receive Regular Updates</h4>
            <p><span className="arrow">✨</span>  Inform users that they'll get regular reports and ongoing SEO insights.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Paymentpremium;
