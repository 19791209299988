import React from 'react';
import { Link } from 'react-router-dom';  
import { useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import '../styles/call/header.css';
import '../styles/call/call.css';

function Call(){

  const location = useLocation();
  
  // Scroll restoration logic (if needed)
  useEffect(() => {
    const scrollPositioncall = sessionStorage.getItem('scrollPositioncall');
    if (scrollPositioncall) {
      window.scrollTo(0, parseInt(scrollPositioncall, 10));
    }
    const handleScroll = () => {
      sessionStorage.setItem('scrollPositioncall', window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div>
       
        <div className="header">
            <Link to="/">
                <img className="logo" src="images/3.png" alt="logo" />
            </Link>
            <Link to={'/'} className="welcome">Welcome</Link>
            <Link to="/discover" className="discover">
                <div>Discover</div>
            </Link>
            <Link to="/pricing" className="pricing">
                <div>Pricing</div>
            </Link>
            <Link to="/call" className="call">
                <div>Call us</div>
            </Link>
        </div>
            <>
                <Link to={'/Login'}>
                <div className="login-icon">
                   <i className="fas fa-user"></i> Login
                </div>
                </Link>
            </>

        <section className='call-section'>

          <div className='call-form'>
              <div className='form-heading'>
                  <h1>Lets talk</h1>
              </div>
              <div className='form-text'>
                <input type="text" placeholder='*Name' />
                <input type="text" placeholder='*Email' />
                <input type="text" placeholder='*Phone' />
                <input type="text" placeholder='*Website url' />
                <textarea type="text" placeholder='Tell us about your goals' className='message' />

                <button className='submit-button'>Submit</button>
              </div>
          </div>
          <div className='call-text'>
              <div>
              <h1>What Happens Next?</h1>
              </div>
              <div>
                <h4>Meeting Scheduled</h4>
                <p><span class="arrow">➜</span> We'll contact you within 24 hours to book a call.</p>
              </div>

              <div>
                <h4>Project Discussion</h4>
                <p> <span class="arrow">➜</span> We’ll explore your SEO needs and objectives.</p>
              </div>

              <div>
                <h4>Plan Selection</h4>
                <p> <span class="arrow">➜</span>Choose the best plan to meet your goals.</p>
              </div>

              <div>
                <h4>Let’s Get Started!</h4>
                <p> <span class="arrow">➜</span>Once confirmed, we’ll begin optimizing right away.</p>
              </div>
          </div>

        </section>



    </div>

  )
}

export default Call