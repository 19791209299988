import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import '../styles/pricing/cta.css';
import '../styles/pricing/footer.css';
import '../styles/pricing/header.css';
import '../styles/pricing/pricing.css';


function Pricing() {

  
    const location = useLocation();
  
    // Scroll restoration logic (if needed)
    useEffect(() => {
      const scrollPositionpricing = sessionStorage.getItem('scrollPositionpricing');
      if (scrollPositionpricing) {
        window.scrollTo(0, parseInt(scrollPositionpricing, 10));
      }
      const handleScroll = () => {
        sessionStorage.setItem('scrollPositionpricing', window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [location]);
  



  return (
    <div>
      
        <div className="header">
            <Link to="/"><img className="logo" src="images/3.png" alt="logo" /></Link>
            <Link to="/" className="welcome">Welcome</Link>
            <Link to="/Discover" className="discover">Discover</Link>
            <Link to="/Pricing" className="pricing">Pricing</Link>
            <Link to="/Call" className="call">Call us</Link>
        </div>
            <>
                <Link to={'/Login'}>
                <div className="login-icon">
                   <i className="fas fa-user"></i> Login
                </div>
                </Link>
            </>

        <section className="hero-section">
          <h1>Our SEO Pricing Plans</h1>
          <p>Choose the plan that fits your business needs and budget.</p>
          <a href="#pricing" className="explore-button">Explore Plans</a>
        </section>

        <section id="pricing">
          <div className="plan lite">
            <h2>Lite Plan</h2>
            <p><strong>$0</strong> / month</p>
            <p>Ideal for small websites or personal projects needing basic SEO checks.</p>
            <a href="/Pricing">Try it free!</a>
            <ul>
              <li>✔️ Website audit: Detailed audit to identify key issues affecting performance.</li>
              <li>✔️ Problem identification: Highlight issues that hinder higher rankings.</li>
              <li>✔️ Actionable recommendations: Expert advice to improve overall SEO health.</li>
            </ul>
          </div>

          <div className="plan base">
            <h2>Base Plan</h2>
            <p><strong>$250</strong> / month</p>
            <p>Great for growing websites needing ongoing SEO analysis and insights.</p>
            <Link to="/Paymentbase">Choose Plan</Link>
            <ul>
              <li>✔️ In-depth SEO analysis: Comprehensive audit to optimize for search engines.</li>
              <li>✔️ Technical SEO audit: Identify issues like slow load times and broken links.</li>
              <li>✔️ Monthly SEO reports: Track progress with detailed monthly reports.</li>
              <li>✔️ Keyword optimization insights: Tailored suggestions for better keyword use.</li>
              <li>✔️ Email & chat support: Assistance and guidance for implementing recommendations.</li>
            </ul>
          </div>

          <div className="plan standard">
            <h2>Standard Plan</h2>
            <p><strong>$450</strong> / month</p>
            <p>Perfect for businesses aiming to enhance content and improve rankings.</p>
            <Link to="/Paymentstandard">Choose Plan</Link>
            <ul>
              <li>✔️ Advanced SEO audits: Deep dive into SEO, from keyword optimization to link structure.</li>
              <li>✔️ Content optimization strategies: Recommendations to improve content engagement.</li>
              <li>✔️ Competitor analysis: Insights to stay ahead of competitors.</li>
              <li>✔️ Monthly SEO reports with insights: Detailed reports with actionable insights.</li>
              <li>✔️ Email & phone support: Fast help via phone and email for your strategy needs.</li>
            </ul>
          </div>

          <div className="plan premium">
            <h2>Premium Plan</h2>
            <p><strong>$999</strong> / month</p>
            <p>Ideal for large businesses or e-commerce sites needing full-scale SEO management.</p>
            <Link to="/Paymentpremium">Choose Plan</Link>
            <ul>
              <li>✔️ Complete SEO management: End-to-end services, from fixes to ongoing optimization.</li>
              <li>✔️ Tailored content marketing strategy: Custom strategy to build brand and visibility.</li>
              <li>✔️ Advanced link-building campaigns: High-quality backlinks tailored to your industry.</li>
              <li>✔️ Priority SEO audits: Fast service to keep your site in top shape.</li>
              <li>✔️ Dedicated priority support: Priority access to our team via email, phone, and consultations.</li>
            </ul>
          </div>
        </section>

        <section className="video-cta-section">
          <div className="video-container">
            <img src="images/Screenshot 2024-09-13 145000.png" alt="SEO analysis video" autoPlay loop muted playsInline />
          </div>
          <div className="cta-container">
            <h2>Get Personalized SEO Recommendations</h2>
            <p>Upload your website URL, and let us analyze it to suggest the best SEO plan for you. Our expert team will provide a tailored strategy to boost your online presence.</p>
            <Link to="/Call" className="cta-button">Analyze My Website</Link>
          </div>
        </section>

        <footer className="footer">
          <div className="footer-section footer-logo">
            <img src="images/3.png" alt="Vyrex Logo" className="logo" />
          </div>
          <div className="footer-container">
            {/* Logo and Company Name */}

            {/* Quick Links */}
            <div className="footer-section footer-links">
              <h4>Quick Links</h4>
              <ul>
                    <li><Link to="/Home#introduction-section">Home</Link></li>
                    <li><a href="#about-us">About Us</a></li>
                    <li><a href="/Home#services-overview">Services</a></li>
                    <li><a href="#blog">Blog</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                    <li><a href="pricing.js">Pricing</a></li>
                </ul>
            </div>

            {/* Legal Links */}
            <div className="footer-section footer-legal">
              <h4>Legal</h4>
              <ul>
                  <li><a href="#terms-of-use">Terms of Use</a></li>
                  <li><a href="#privacy-policy">Privacy Policy</a></li>
                  <li><a href="#cookie-policy">Cookie Policy</a></li> 
                  <li><a href="#disclaimer">Disclaimer</a></li>
                  <li><Link to="/Discover#faq-section">FAQ</Link></li>
              </ul>
            </div>

            {/* Social Media Links */}
            <div className="footer-section footer-social">
              <h4>Follow Us</h4>
              <div className="social-icons">
                <a href="https://facebook.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/facebook-new.png" alt="Facebook" /></a>
                <a href="https://twitter.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/twitter.png" alt="Twitter" /></a>
                <a href="https://linkedin.com"><img src="https://img.icons8.com/ios-filled/30/ffffff/linkedin.png" alt="LinkedIn" /></a>
              </div>
            </div>

            {/* Contact Information (Optional) */}
            {/* Uncomment if needed */}
            {/* <div className="footer-section footer-contact">
              <h4>Contact Us</h4>
              <p><strong>Email:</strong> <a href="mailto:info.vyrex@gmail.com">info.vyrex@gmail.com</a></p>
            </div> */}
          </div>

          {/* Divider */}
          <hr className="footer-divider" />

          {/* Copyright */}
          <div className="footer-bottom">
            <p>© 2024 Vyrex. All rights reserved.</p>
          </div>
        </footer>

    </div>

  )

}

export default Pricing;

