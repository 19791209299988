import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home.js';
import Discover from './components/discover.js'; // 
import Pricing from './components/pricing.js'; // 
import Call from './components/call'; //
import Login from './components/login.js';
import Paymentbase from './components/payment/payment-base.js';
import Paymentpremium from './components/payment/payment-premium.js';
import Paymentstandard from './components/payment/payment-standard.js';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/call" element={<Call />} />
        <Route path="/login" element={<Login />} />
        <Route path="/paymentbase" element={<Paymentbase />} />
        <Route path="/paymentpremium" element={<Paymentpremium />} />
        <Route path="/paymentstandard" element={<Paymentstandard />} /> 

      </Routes>
    </Router>
  );
}

export default App;
